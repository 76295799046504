<template>
  <v-progress-circular
    indeterminate
    color="primary"
    class="page_loading align-center justify-center ma-auto"
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
