export default {
  methods: {
    dynamicColor (type) {
      const styles = {}
      switch (type) {
        case 'region': {
          styles.color = '#49003e'
          break
        }
        case 'district': {
          styles.color = '#f98019'
          break
        }
        case 'settlement': {
          styles.color = '#00c453'
          break
        }
        case 'auction': {
          styles.color = '#4865F3AD'
          break
        }
        case 'area': {
          styles.color = '#000000'
          break
        }
        default: {
          styles.color = '#777676'
        }
      }
      return styles
    }
  }
}
